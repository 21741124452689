<script>
import { Icon } from "@iconify/vue";
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: `KalenderView`,
  setup() {
    const { cookies } = useCookies();
    const kalenderPos = ref(0);

    return {
      kalenderPos,
      cookies,
    };
  },
  mounted() {
    this.initializeOpenDoors();
  },
  components: {
    HeaderComponent,
    Icon,
  },
  data: () => ({
    eventsList: [
      {
        day: 1,
        name: "Meilenstein",
        address: "In der Meile 2",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 2,
        name: "Arminia Marten",
        address: "SV Arminia Marten",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 4,
        name: "Steinhammer GS",
        address: "Steinhammer Grundschule",
        time: "17:00 Uhr",
        open: null,
      },
      {
        day: 5,
        name: "ZWAR",
        address: "Steinhammerstr. 3",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 6,
        name: "Trauerkaffe, Carola",
        address: "Imanuel-Kirche Bärenbruch",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 7,
        name: "Melanie",
        address: "Lina Schäfer Str. 42",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 8,
        name: "Apotheke",
        address: "An der Wasserburg 33",
        time: "19:00 Uhr",
        open: null,
      },
      {
        day: 9,
        name: "Ohne",
        address: "Leythestraße 8",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 11,
        name: "Bethel Regional",
        address: "Lina Schäfer Str. 37",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 12,
        name: "Hunscheidt",
        address: "Martener Str. 344",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 13,
        name: "Föhrs",
        address: "Auf der Kiste 44",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 14,
        name: "JFS",
        address: "Wischlinger Weg 239",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 15,
        name: "Horsten",
        address: "Martener Str. 319a",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 16,
        name: "Margarete",
        address: "Am Häugter 14",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 18,
        name: "Ohne",
        address: "Auf der Kiste 26",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 20,
        name: "Denk",
        address: "Kaspar Schulte Str. 31",
        time: "18:00 Uhr",
        open: null,
      },
      {
        day: 21,
        name: "Kath. Fam. Zentrum",
        address: "Sadelhof 16",
        time: "17:00 Uhr",
        open: null,
      },
      {
        day: 22,
        name: "Hof Korte",
        address: "An der Wasserburg 44",
        time: "18:00 Uhr",
        open: null,
      },
    ],
  }),
  methods: {
    initializeOpenDoors() {
      console.log("Initialize");
      const today = new Date(Date.now());

      if (today.getMonth() + 1 === 12) {
        this.eventsList.forEach((door, index) => {
          const doorDay = parseInt(door.day);
          if (doorDay < today.getDate()) {
            door.open = "img-active";
          } else if (doorDay === today.getDate()) {
            // Door is today, mark the position
            this.kalenderPos = index;
            if (this.cookies.get(doorDay.toString())) {
              door.open = "img-active";
            }
          } else {
            // Door is in the future, no need to toggle the class
          }
        });
      }
    },

    triggerDoor(id) {
      const today = new Date(Date.now());
      if (
        today.getMonth() + 1 === 12 &&
        id.toString() === today.getDate().toString()
      ) {
        this.eventsList.forEach((door, index) => {
          const doorDay = parseInt(door.day);
          if (doorDay === id) {
            this.eventsList[index].open = "img-active";
            this.cookies.set(id, "1");
          }
        });
      }
      console.log(id);
      console.log(this.eventsList);
    },
  },
};
</script>

<template>
  <HeaderComponent></HeaderComponent>
  <div class="page d-md-flex d-sm-none align-center">
    <div class="kalender-container">
      <div v-for="date in eventsList" :key="date.day" class="wrapper">
        <!--        <div
                  v-bind:id="date.day"
                  :style="
                    'background-image: url(&quot;' +
                    require(`../assets/door/${date.day}.png`) +
                    '&quot;)'
                  "
                  v-bind:class="'doorImg ' + date.open"
                  @click="triggerDoor"
                ></div>-->
        <v-img
          v-bind:id="date.day"
          :lazy-src="require(`../assets/door/${date.day}-low.webp`)"
          :src="require(`../assets/door/${date.day}.webp`)"
          alt="Wichteltür"
          class="doorImg"
          v-bind:class="date.open"
          @click="triggerDoor(date.day)"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </template>
        </v-img>
        <div class="description d-flex align-center justify-center">
          <v-row v-if="date.open" class="h-100 w-100">
            <v-col cols="12">
              <span class="day">{{ date.day }}</span>
            </v-col>
            <v-col class="d-flex align-center justify-center" cols="12">
              <a
                :href="
                  'https://maps.google.de/maps?q=' +
                  date.address.replaceAll(' ', '+')
                "
              >
                <Icon
                  class="map"
                  icon="streamline:travel-map-location-pin-navigation-map-maps-pin-gps-location"
                />
              </a>
            </v-col>
            <v-col cols="12">
              <span class="time">{{ date.time }}</span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
  <div class="page d-sm-flex d-md-none d-lg-none d-xl-none align-center">
    <v-carousel
      v-model="kalenderPos"
      :continuous="false"
      height="95vh"
      hide-delimiters
      theme="light"
    >
      <v-carousel-item v-for="(date, index) in eventsList" :key="index">
        <v-card class="bg-transparent h-100 d-flex align-center justify-center">
          <div
            class="wrapper wrapper-crousel d-flex align-center justify-center"
          >
            <v-img
              v-bind:id="date.day"
              :lazy-src="require(`../assets/door/${date.day}-low.webp`)"
              :src="require(`../assets/door/${date.day}.webp`)"
              alt="Wichteltür"
              class="doorImg"
              v-bind:class="date.open"
              @click="triggerDoor(date.day)"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
            <div class="description d-flex align-center justify-center">
              <v-row v-if="date.open" class="h-100 w-100">
                <v-col cols="12">
                  <span class="day">{{ date.day }}</span>
                </v-col>
                <v-col class="d-flex align-center justify-center" cols="12">
                  <a
                    :href="
                      'https://maps.google.de/maps?q=' +
                      date.address.replaceAll(' ', '+')
                    "
                  >
                    <Icon
                      class="map"
                      icon="streamline:travel-map-location-pin-navigation-map-maps-pin-gps-location"
                    />
                  </a>
                </v-col>
                <v-col cols="12">
                  <span class="time">{{ date.time }}</span>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<style scoped>
.kalender-container {
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  overflow: hidden;
}

.page {
  width: 100%;
  position: absolute;
  /*noinspection CssInvalidPropertyValue*/
  min-height: -webkit-fill-available;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../assets/christmas_landscape_desktop-1920x1080.webp");
}

.wrapper {
  aspect-ratio: 2478/3686;
  position: relative;
  border: 8px solid #004d40;
  perspective: 1000px;
}

.wrapper-crousel {
  height: auto;
  width: 60%;
}

.description {
  align-content: center;
  text-align: center;
  background-color: black;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  word-wrap: anywhere;
  position: absolute;
  top: 0;
  color: white;
  z-index: -1;
}

/* Image Style */
.doorImg {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  aspect-ratio: 2478/3686;
  object-fit: cover;
  transition: all 0.5s;
  transform-origin: right;
}

.img-active {
  transform: rotateY(90deg);
}

.map {
  font-size: 4em;
}

.time,
.day {
  font-size: 1em;
}

@media (max-width: 1160px) {
  .kalender-container {
    gap: 5px;
  }
}

@media (max-width: 960px) {
  .map {
    font-size: 10em;
  }

  .time,
  .day {
    font-size: 1.5em;
  }
}

@media (orientation: landscape) and (min-width: 1000px) {
  .map {
    font-size: 3em;
  }

  .time,
  .day {
    font-size: 1em;
  }
}

@media (orientation: portrait) and (min-width: 1000px) {
  .wrapper {
    height: 19vh;
  }
}

@media (orientation: portrait) and (max-width: 350px) {
  .map {
    font-size: 6em;
  }

  .time,
  .day {
    font-size: 1.5em;
  }
}

@media (orientation: landscape) and (min-aspect-ratio: 1.3/1) {
  .wrapper {
    height: auto;
    width: 12vw;
  }
}

@media (orientation: landscape) and (min-aspect-ratio: 4/2) {
  .wrapper {
    height: auto;
    width: 9vw;
  }
}
</style>
