<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "AboutView",
  components: { HeaderComponent },
  data: () => ({
    title: "Fröhliche Töne sind in den Straßen von Marten zu hören.",
    opener:
      "Seit vielen Jahren bringt der Advent die Nachbarschaft zusammen. " +
      "Heiter und besinnlich wird im Schein der Kerzen, die die Dunkelheit verdrängen, miteinander gesungen und gelacht. " +
      "Lieder und Geschichten durchdringen die Stille und ein heißes Getränk wärmt von innen in der winterlichen Kälte.\n",
    text:
      "Wenn Sie sich auch auf diese Weise verzaubern lassen wollen, dann ist der wandernde Adventskalender genau das Richtige für Sie.\n" +
      "Beinahe jeden Tag kommen wir an einem anderen Ort in Marten zusammen, um gemeinsam zu singen.\n" +
      "Wie die Türchen eines Adventskalenders öffnen sich hier Tag für Tag die Herzen. In der Regel finden die Treffen von 18-19 Uhr statt. (Ausnahmen sind vermerkt)",
  }),
};
</script>

<template>
  <HeaderComponent></HeaderComponent>
  <div class="page">
    <v-row class="h-100 d-flex justify-center">
      <v-col cols="10" md="8">
        <v-card
          class="mt-16 text-white rounded-card"
          rounded
          style="background-color: rgba(0, 77, 64, 0.92)"
        >
          <v-card-title class="text-wrap" style="word-break: break-word">
            {{ this.title }}
          </v-card-title>
          <v-card-text style="max-height: 390px; overflow-y: auto">
            <p>{{ this.opener }}</p>
            <p>{{ this.text }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.page {
  height: calc(100vh - 64px);
  width: 100vw;
  /*noinspection CssInvalidPropertyValue*/
  min-height: -webkit-fill-available;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../assets/christmas_landscape_desktop-1920x1080.jpg");
}

.rounded-card {
  border-radius: 24px;
}
</style>
