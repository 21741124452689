<script>
import { Icon } from "@iconify/vue";
import router from "@/router";

export default {
  name: "HeaderComponent",
  methods: {
    router() {
      return router;
    },
  },
  components: {
    Icon,
  },
  data: () => ({
    nav: [
      {
        icon: "/",
        text: "Home",
        title: "Zurück zur Startseite",
        active: true,
      },
      {
        icon: "info",
        text: "About",
        title: "Über dieses Event",
        active: false,
      },
      {
        icon: "anmelden",
        text: "Anmelden",
        title: "Jetzt anmelden für nächstes Jahr!",
        active: false,
      },
    ],
    drawer: false,
    place_select: 0,
  }),
};
</script>

<template>
  <v-app-bar absolute="" app color="teal-darken-4">
    <v-row class="d-flex align-center" style="height: 79px">
      <v-col cols="2" md="4">
        <v-app-bar-nav-icon
          class="menu hidden-md-and-up"
          @click.stop="drawer = !drawer"
        >
          <Icon icon="iconamoon:menu-kebab-vertical-bold" />
        </v-app-bar-nav-icon>
        <div class="hidden-sm-and-down">
          <v-btn
            v-for="item in nav"
            :key="item.icon"
            :title="item.title"
            :to="item.icon"
            class="bg-transparent"
          >
            {{ item.text }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="text-center" cols="10" md="6">
        <v-app-bar-title class="text-md-h6 text-start"
          >Advenskalender 2023
        </v-app-bar-title>
      </v-col>
      <v-col class="h-100" cols="2">
        <v-select
          v-model="place_select"
          :disabled="true"
          :items="[{ id: 0, ort: 'Marten' }]"
          item-title="ort"
          item-value="id"
          label="Select"
        ></v-select>
      </v-col>
    </v-row>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" location="bottom" temporary>
    <v-list-item v-for="(item, i) in nav" :key="i" :to="item.icon" link>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<style scoped>
.menu {
  font-size: 2em;
}
</style>
