<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import emailjs from "emailjs-com";

export default {
  name: "AnmeldenView",
  components: { HeaderComponent },
  data: () => ({
    valid: false,
    message: "Ich möchte 2024 beim Adventsingen ein Fenster ausrichten.",
    name: "",
    nameRules: [
      (value) => {
        if (value) return true;

        return "Name is required.";
      },
      (value) => {
        if (value?.length <= 20) return true;

        return "Name must be less than 10 characters.";
      },
    ],
    email: "",
    emailRules: [
      (value) => {
        if (value) return true;

        return "E-mail is required.";
      },
      (value) => {
        if (/.+@.+\..+/.test(value)) return true;

        return "E-mail must be valid.";
      },
    ],
  }),
  methods: {
    sendEmail(e) {
      if (this.valid) {
        try {
          e.value = "Sending...";

          const serviceID = "service_89s88om";
          const templateID = "template_a03h4zz";

          emailjs
            .sendForm(serviceID, templateID, "#anmelden", "3DiGwo2qQmwddmzlY")
            .then(
              () => {
                e.value = "Send Email";
                alert("Sent!");
              },
              (err) => {
                e.value = "Send Email";
                alert(JSON.stringify(err));
              }
            );
        } catch (error) {
          console.log({ error });
        }
        // Reset form field
        this.name = "";
        this.email = "";
        this.message =
          "Ich möchte 2024 beim Adventsingen ein Fenster ausrichten.";
      }
    },
  },
};
</script>

<template>
  <HeaderComponent></HeaderComponent>
  <div class="page">
    <v-row class="h-100 d-flex justify-center align-center">
      <v-col cols="10" md="6">
        <v-card
          class="text-white rounded-card"
          height="500"
          shaped
          style="background-color: rgba(0, 77, 64, 0.92)"
        >
          <v-row class="d-flex justify-center h-100">
            <v-col class="d-flex justify-center" cols="12">
              <v-card-title>Jetzt für Nächstes Jahr anmelden!</v-card-title>
            </v-col>
            <v-col class="d-flex justify-center w-100" cols="12">
              <v-form id="anmelden" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        id="name"
                        v-model="name"
                        :counter="10"
                        :rules="nameRules"
                        hide-details
                        label="Name"
                        name="name"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        id="email"
                        v-model="email"
                        :rules="emailRules"
                        hide-details
                        label="E-mail"
                        name="email"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        id="message"
                        v-model="message"
                        label="Deine Nachricht"
                        name="message"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="black" @click="sendEmail">Senden</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.page {
  height: calc(100vh - 64px);
  width: 100vw;
  /*noinspection CssInvalidPropertyValue*/
  min-height: -webkit-fill-available;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("../assets/christmas_landscape_desktop-1920x1080.jpg");
}

input {
  width: 90%;
}

textarea {
  width: 90%;
}

.rounded-card {
  border-radius: 24px;
}
</style>
